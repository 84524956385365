// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import VideoLibrary from "../../blocks/VideoLibrary/src/VideoLibrary";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import RecommendationEngine4 from "../../blocks/RecommendationEngine4/src/RecommendationEngine4";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import AudioLibrary from "../../blocks/AudioLibrary/src/AudioLibrary";
import Leaderboard from "../../blocks/Leaderboard/src/Leaderboard";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import ConferenceCall from "../../blocks/ConferenceCall/src/ConferenceCall";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Maps from "../../blocks/maps/src/Maps";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import AudioCall from "../../blocks/AudioCall/src/AudioCall";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Videos from "../../blocks/videos/src/Videos";
import CfCourseModules from "../../blocks/CfCourseModules/src/CfCourseModules";
import SmsSettings from "../../blocks/SmsSettings/src/SmsSettings";
import Gamification from "../../blocks/Gamification/src/Gamification";
import Customform from "../../blocks/customform/src/Customform";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Notifications from "../../blocks/notifications/src/Notifications";
import Chatbot6 from "../../blocks/Chatbot6/src/Chatbot6";
import LiveStreaming from "../../blocks/LiveStreaming/src/LiveStreaming";
import VideoCall5 from "../../blocks/VideoCall5/src/VideoCall5";
import Analytics from "../../blocks/analytics/src/Analytics";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import DesktopNotifications from "../../blocks/desktopnotifications/src/DesktopNotifications";
import QuickbooksIntegration3 from "../../blocks/QuickbooksIntegration3/src/QuickbooksIntegration3";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import StoreCredits from "../../blocks/StoreCredits/src/StoreCredits";
import UserGroups2 from "../../blocks/UserGroups2/src/UserGroups2";
import ManageBlogComments from "../../blocks/ManageBlogComments/src/ManageBlogComments";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import ProductDescription from "../../blocks/ProductDescription/src/ProductDescription";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import TermsAndConditions3 from "../../blocks/TermsAndConditions3/src/TermsAndConditions3";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import TimeTrackingBilling from "../../blocks/TimeTrackingBilling/src/TimeTrackingBilling";
import Share from "../../blocks/share/src/Share";
import DocusignIntegration from "../../blocks/DocusignIntegration/src/DocusignIntegration";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AutomaticReminders from "../../blocks/AutomaticReminders/src/AutomaticReminders";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import GroupVideoCall2 from "../../blocks/GroupVideoCall2/src/GroupVideoCall2";
import CfChangeHealthcareAdonnisApi from "../../blocks/CfChangeHealthcareAdonnisApi/src/CfChangeHealthcareAdonnisApi";
import Surveys from "../../blocks/Surveys/src/Surveys";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import LanguageOptions from "../../blocks/LanguageOptions/src/LanguageOptions";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import HamburgerMenu from "../../blocks/HamburgerMenu/src/HamburgerMenu";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import CfGetHealthyApi from "../../blocks/CfGetHealthyApi/src/CfGetHealthyApi";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import HubspotIntegration from "../../blocks/HubspotIntegration/src/HubspotIntegration";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import FileAttachment from "../../blocks/FileAttachment/src/FileAttachment";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Chat from "../../blocks/chat/src/Chat";
import ChatView from "../../blocks/chat/src/ChatView";
import DailyScheduleNotifications from "../../blocks/DailyScheduleNotifications/src/DailyScheduleNotifications";
import TimeTracker from "../../blocks/TimeTracker/src/TimeTracker";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CommunityForum from "../../blocks/CommunityForum/src/CommunityForum";
import CreateComment from "../../blocks/comments/src/CreateComment";



const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
RecommendationEngine4:{
 component:RecommendationEngine4,
path:"/RecommendationEngine4"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
AudioLibrary:{
 component:AudioLibrary,
path:"/AudioLibrary"},
Leaderboard:{
 component:Leaderboard,
path:"/Leaderboard"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
ConferenceCall:{
 component:ConferenceCall,
path:"/ConferenceCall"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Maps:{
 component:Maps,
path:"/Maps"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
AudioCall:{
 component:AudioCall,
path:"/AudioCall"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Videos:{
 component:Videos,
path:"/Videos"},
CfCourseModules:{
 component:CfCourseModules,
path:"/CfCourseModules"},
SmsSettings:{
 component:SmsSettings,
path:"/SmsSettings"},
Gamification:{
 component:Gamification,
path:"/Gamification"},
Customform:{
 component:Customform,
path:"/Customform"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Chatbot6:{
 component:Chatbot6,
path:"/Chatbot6"},
LiveStreaming:{
 component:LiveStreaming,
path:"/LiveStreaming"},
VideoCall5:{
 component:VideoCall5,
path:"/VideoCall5"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
DesktopNotifications:{
 component:DesktopNotifications,
path:"/DesktopNotifications"},
QuickbooksIntegration3:{
 component:QuickbooksIntegration3,
path:"/QuickbooksIntegration3"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
StoreCredits:{
 component:StoreCredits,
path:"/StoreCredits"},
UserGroups2:{
 component:UserGroups2,
path:"/UserGroups2"},
ManageBlogComments:{
 component:ManageBlogComments,
path:"/ManageBlogComments"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
ProductDescription:{
 component:ProductDescription,
path:"/ProductDescription"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
TermsAndConditions3:{
 component:TermsAndConditions3,
path:"/TermsAndConditions3"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
TimeTrackingBilling:{
 component:TimeTrackingBilling,
path:"/TimeTrackingBilling"},
Share:{
 component:Share,
path:"/Share"},
DocusignIntegration:{
 component:DocusignIntegration,
path:"/DocusignIntegration"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
AutomaticReminders:{
 component:AutomaticReminders,
path:"/AutomaticReminders"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
GroupVideoCall2:{
 component:GroupVideoCall2,
path:"/GroupVideoCall2"},
CfChangeHealthcareAdonnisApi:{
 component:CfChangeHealthcareAdonnisApi,
path:"/CfChangeHealthcareAdonnisApi"},
Surveys:{
 component:Surveys,
path:"/Surveys"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
HamburgerMenu:{
 component:HamburgerMenu,
path:"/HamburgerMenu"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
CfGetHealthyApi:{
 component:CfGetHealthyApi,
path:"/CfGetHealthyApi"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
HubspotIntegration:{
 component:HubspotIntegration,
path:"/HubspotIntegration"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
EmailNotifications2:{
 component:EmailNotifications2,
path:"/EmailNotifications2"},
FileAttachment:{
 component:FileAttachment,
path:"/FileAttachment"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Chat:{
 component:Chat,
path:"/Chat"},
ChatView:{
 component:ChatView,
path:"/ChatView"},
DailyScheduleNotifications:{
 component:DailyScheduleNotifications,
path:"/DailyScheduleNotifications"},
TimeTracker:{
 component:TimeTracker,
path:"/TimeTracker"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
CommunityForum:{
 component:CommunityForum,
path:"/CommunityForum"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},

  Home: {
component:ReviewApprovalAdmin,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
